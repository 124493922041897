import React, { createContext, useContext, useEffect, useState } from "react";
import http from "../http";

import $ from "jquery";
import Viewer from "viewerjs";
import * as Masonry from "masonry-layout";
import LazyLoad from "vanilla-lazyload";

const CarpenterContext = createContext();

export const useCarpenterContext = () => {
  return useContext(CarpenterContext);
};

export const CarpenterProvider = ({ children }) => {
  const [workCategories, setWorkCategories] = useState([]);
  const [banners, setBanners] = useState([]);
  const [footer, setFooter] = useState({});
  const [works, setWorks] = useState([]);
  const [aboutUs, setAboutUs] = useState({});
  const [aboutUsSet, setAboutUsSet] = useState(false);
  const [siteMeta, setSiteMeta] = useState({
    title: 'J.Y. Woodwork',
    logo: 'favicon.ico',
  });

  useEffect(() => {
    const getAllWorks = async () => {
      let arr = [];
      let page = 1;
      while (true) {
        const response = await http.get("/api/works", {
          params: {
            populate: "*",
            "sort[0]": "title",
            "pagination[page]": page++,
            "pagination[pageSize]": 25,
          },
        });
        const responseArr = Object.values(response.data.data);
        if (responseArr.length === 0) {
          break;
        }
        arr = [...arr, ...responseArr];
      }
      setWorks(arr);
    };
    getAllWorks();
  }, []);

  useEffect(() => {
    const getAllWorkCategories = async () => {
      let arr = [];
      let page = 1;
      while (true) {
        const response = await http.get("/api/work-categories", {
          params: {
            populate: "*",
            "sort[0]": "order:desc",
            "sort[1]": "title",
            "pagination[page]": page++,
            "pagination[pageSize]": 25,
          },
        });
        const responseArr = Object.values(response.data.data);
        if (responseArr.length === 0) {
          break;
        }
        arr = [...arr, ...responseArr];
      }
      setWorkCategories(arr);
    };
    getAllWorkCategories();
  }, []);

  useEffect(() => {
    const getAllBanner = async () => {
      const response = await http.get("/api/banner", {
        params: {
          populate: "*",
        },
      });
      setBanners(response.data.data.attributes.banners.data);
    };
    getAllBanner();
  }, []);

  useEffect(() => {
    const getAboutUs = async () => {
      const response = await http.get("/api/about-us", {
        params: {
          populate: "*",
        },
      });
      setAboutUs(response.data.data.attributes);
      setTimeout(() => {
        setAboutUsSet(true)
      }, 1000);
    };
    getAboutUs();
  }, []);

  useEffect(() => {
    const getAllFooter = async () => {
      const response = await http.get("/api/footer", {
        params: {
          populate: "*",
        },
      });
      setFooter(response.data.data.attributes);
    };
    getAllFooter();
  }, []);

  useEffect(() => {
    initJs();
  }, [works, workCategories]);

  const initJs = () => {
    let masonries = [];
    const gutter = 8;
    document
      .querySelectorAll(".prod-grid-row > .prod-grid-container")
      .forEach((elem) => {
        const msnry = new Masonry(elem, {
          itemSelector: ".prod-grid",
          fitWidth: true,
          gutter,
        });
        masonries.push(msnry);
      });

    const reloadMsnry = () => {
      masonries.map((msnry) => {
        const width = $(".prod-grid-row").width();
        let n = 5;
        if (width < 600) {
          n = 2;
        } else if (width < 800) {
          n = 3;
        } else if (width < 1000) {
          n = 4;
        }
        const w = (width - gutter * (n - 1)) / n;
        $(".prod-grid").width(w);
        msnry.layout();
      });
    };

    const reloadMsnryItems = () => {
      masonries.map((msnry) => {
        msnry.reloadItems();
      });
    };

    const lazyLoadInstance = new LazyLoad({
      callback_error: () => reloadMsnry(),
      callback_loaded: () => reloadMsnry(),
      callback_finish: () => reloadMsnry(),
    });

    $(window).on("resize", function () {
      reloadMsnry();
    });

    $("body").on("click", ".catalog-filter", function () {
      const filter = $(this).data("filter");
      $("button.catalog-filter.btn-dark")
        .addClass("btn-outline-dark")
        .removeClass("btn-dark");
      $("button.catalog-filter.catalog-filter-" + filter)
        .removeClass("btn-outline-dark")
        .addClass("btn-dark");
      const container = $(".prod-grid-row > .prod-grid-container");
      container.empty();
      if (filter === "all") {
        $(".prod-grid-master").find(".prod-grid").clone().appendTo(container);
      } else {
        $(".prod-grid-master")
          .find(".prod-grid-" + filter)
          .clone()
          .appendTo(container);
      }
      lazyLoadInstance.update();
      reloadMsnryItems();
      reloadMsnry();
    });

    $("body").on("click", ".prod-img", function () {
      new Viewer($(this)[0], {
        toolbar: { prev: 1, next: 1 },
        title: [1, (image, _) => `${image.alt}`],
      }).show();
    });
  };

  const value = {
    workCategories,
    banners,
    footer,
    works,
    aboutUs,
    aboutUsSet,
    siteMeta,
    initJs,
  };

  return (
    <CarpenterContext.Provider value={value}>
      {children}
    </CarpenterContext.Provider>
  );
};
