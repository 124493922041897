import React from "react";

const ProductItem = ({ data, gridItemClass }) => {
  const id = data.id;
  const attr = data.attributes;
  const classes = attr.work_categories.data
    .map((category) => {
      return gridItemClass + "-" + category.id;
    })
    .join(" ");
  const thumbnail =
    process.env.REACT_APP_API_BASE_URL + attr.thumbnail.data?.attributes.formats.large.url;

  return (
    <div class={`${gridItemClass} ${classes}`}>
      <div class="card shadow-lg grid">
        <img
          class="lazy img-fluid card-img-top prod-img"
          data-gallery-id={id}
          data-src={thumbnail}
          alt={attr.title}
        />
        <div class="card-body">
          <h4 class="card-title">{attr.title}</h4>
          <p class="card-text text-muted">
            {attr.description}
            &nbsp;
            <span class="badge rounded-pill bg-light text-dark prod-sku">
              {attr.sku}
            </span>
          </p>
        </div>
        <div id={`prod-img-list-${id}`} class="display-none">
          <ul>
            {
              attr.images.data?.map((image) => {
                return (
                  <li key={image.id}>
                    <img src={process.env.REACT_APP_API_BASE_URL + image.attributes.formats.large.url} alt={attr.title} />
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
