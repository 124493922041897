import React from "react";

import ProductItem from './ProductItem';

const Breadcrumb = ({ items }) => {
    return (
        <section id="breadcrumb">
            <div class="container-fluid">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a href="/" alt="主頁">主頁</a></li>
                    {
                        items.map(({ title, url }) => {
                            if (url !== null) {
                                return (
                                    <li key={title} class="breadcrumb-item">
                                        <a href={url} alt={title}>{title}</a>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={title} class="breadcrumb-item active">{title}</li>
                                );
                            }
                        })
                    }
                </ol>
            </div>
        </section>
    )
}

export default Breadcrumb;
