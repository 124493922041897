import showdown from "showdown";

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Banner from "../components/home/Banner";
import Breadcrumb from "../components/common/Breadcrumb";
import InfoBox from "../components/common/InfoBox";

import { useCarpenterContext } from "../contexts/CarpenterContext";

const Home = () => {
  const { aboutUs } = useCarpenterContext();

  return (
    <>
      <Header pageKey="about-us" />
      <Breadcrumb
        items={[
          {
            title: "關於我們",
          },
        ]}
      />
      <section id="about-us">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-md-6 px-5 py-5"
              dangerouslySetInnerHTML={{
                __html: new showdown.Converter().makeHtml(aboutUs.about_us),
              }}
            />
            <div class="col-md-6 px-0">
              <img
                class="img-fluid"
                src={process.env.REACT_APP_API_BASE_URL + aboutUs.image?.data.attributes.formats.large.url}
                alt="About Us Image"
              />
            </div>
          </div>
        </div>
      </section>
      <InfoBox />
      <Footer />
    </>
  );
};

export default Home;
