import React from "react";

import { useCarpenterContext } from "../../contexts/CarpenterContext";

const Banner = ({ pageKey }) => {
  const { siteMeta } = useCarpenterContext();

  return (
    <>
      <header>
        <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              <img src={siteMeta.logo} alt={siteMeta.title} class="nav-logo" />
              {siteMeta.title}
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbar">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class={`nav-link` + (pageKey === 'home' ? ' active' : '')} href="/">
                    主頁
                  </a>
                </li>
                <li class="nav-item">
                  <a class={`nav-link` + (pageKey === 'catalog' ? ' active' : '')} href="/catalog">
                    作品目錄
                  </a>
                </li>
                <li class="nav-item">
                  <a class={`nav-link` + (pageKey === 'about-us' ? ' active' : '')} href="/about-us">
                    關於我們
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div class="header-placeholder">&nbsp;</div>
    </>
  );
};
export default Banner;
