import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Banner from "../components/home/Banner";
import ProductGrid from "../components/common/ProductGrid";
import InfoBox from "../components/common/InfoBox";

import { useCarpenterContext } from "../contexts/CarpenterContext";

const Home = () => {
  const { works, workCategories } = useCarpenterContext();

  const featuringCategories = workCategories.filter(
    (category) => category.attributes.display_in_home_page
  );

  return (
    <>
      <Header pageKey="home" />
      <Banner />
      <section id="home">
        <div class="container">
          {featuringCategories.map((category) => {
            return (
              <div key={category.id} class="prod-grid-row mt-4 mb-4">
                <h3 class="text-center mb-4">{category.attributes.title}</h3>
                <ProductGrid
                  data={works.filter((work) =>
                    work.attributes.work_categories.data?.some(
                      (cat) => category.id === cat.id
                    )
                  )}
                />
              </div>
            );
          })}
        </div>
      </section>
      <InfoBox />
      <Footer />
    </>
  );
};

export default Home;
