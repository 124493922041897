import React from "react";

import { useCarpenterContext } from "../../contexts/CarpenterContext";

const Banner = () => {
  const { banners } = useCarpenterContext();

  return (
    <section id="banner" class="mb-4">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          {banners.map((banner, i) => {
            return (
              <button
                key={i}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={i}
                class="active"
                aria-current="true"
                aria-label={`Slide ${i + 1}"`}
              ></button>
            );
          })}
        </div>
        <div class="carousel-inner">
          {banners.map((banner, i) => {
            return (
              <div key={i} class={`carousel-item ${i === 0 ? "active" : ""}`}>
                <img
                  src={
                    process.env.REACT_APP_API_BASE_URL +
                    banner.attributes.formats.large.url
                  }
                  class="d-block w-100"
                  alt={`Slide ${i + 1}"`}
                />
              </div>
            );
          })}
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
};
export default Banner;
