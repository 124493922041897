import React from "react";
import showdown from "showdown";

import { useCarpenterContext } from "../../contexts/CarpenterContext";

const Footer = () => {
  const { footer, siteMeta } = useCarpenterContext();

  return (
    <footer>
      <div class="container px-5 py-5 text-white">
        <div class="row">
          <div class="col-lg-6">
            <img src={siteMeta.logo} alt={siteMeta.title} class="footer-logo" />
            <h4 class="footer-site-title">
              {siteMeta.title}
            </h4>
            <br />
            <br />
            <div
              class="footer-text"
              dangerouslySetInnerHTML={{
                __html: new showdown.Converter().makeHtml(footer?.description),
              }}
            />
          </div>
          <div class="col-lg-3 py-4">
            <h3 class="footer-list-heading force-uppercase normal-weight">
              網頁資訊
            </h3>
            <ul class="footer-list">
              <li class="list-group-item">
                <a href="/catalog">作品目錄</a>
              </li>
              <li class="list-group-item">
                <a href="/about-us">關於我們</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 py-4">
            <h3 class="footer-list-heading">聯絡我們</h3>
            <ul class="footer-list">
              {footer.whatsapp ? (
                <li class="list-group-item">
                  <a href={`https://wa.me/${footer.whatsapp}`}>WhatsApp</a>
                </li>
              ) : null}
              {footer.twitter ? (
                <li class="list-group-item">
                  <a href={`https://twitter.com/${footer.twitter}`}>Twitter</a>
                </li>
              ) : null}
              {footer.facebook ? (
                <li class="list-group-item">
                  <a href={`https://www.facebook.com/${footer.facebook}`}>
                    Facebook
                  </a>
                </li>
              ) : null}
              {footer.instagram ? (
                <li class="list-group-item">
                  <a href={`https://www.instagram.com/${footer.instagram}`}>
                    Instagram
                  </a>
                </li>
              ) : null}
              {footer.carousell ? (
                <li class="list-group-item">
                  <a
                    href={`https://www.carousell.com.hk/u/${footer.carousell}`}
                  >
                    Carousell
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      <div class="px-5 py-5 text-white text-center">
        {`© Copyright ${new Date().getFullYear()} ${siteMeta.title}`}
      </div>
    </footer>
  );
};
export default Footer;
