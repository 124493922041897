import React, { Component } from "react";
import axios from 'axios';

import Header from "../components/common/Header";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/common/Footer";
import ProductGrid from "../components/common/ProductGrid";
import InfoBox from "../components/common/InfoBox";

import { useCarpenterContext } from '../contexts/CarpenterContext';

export default function Catalog() {
  const { works, workCategories } = useCarpenterContext();

  return (
    <>
      <Header pageKey="catalog" />
      <Breadcrumb
        items={[
          {
            title: "作品目錄",
          },
        ]}
      />
      <section id="catalog">
        <div class="container">
          <div class="card text-center mt-5 prod-filter">
            <h4 class="card-header">作品類別</h4>
            <div class="card-body">
              <button
                type="button"
                class="btn btn-dark rounded-5 catalog-filter catalog-filter-all"
                data-filter="all"
              >
                顯示所有作品
              </button>
              {workCategories.map(({ id, attributes }) => {
                return (
                  <button
                    key={id}
                    data-filter={id}
                    type="button"
                    class={`btn btn-outline-dark rounded-5 catalog-filter catalog-filter-${id}`}
                  >
                    {attributes.title}
                  </button>
                );
              })}
            </div>
          </div>
          <div class="prod-grid-row mt-3 mb-4">
            <ProductGrid data={works} />
          </div>
          <div class="prod-grid-master display-none">
            <ProductGrid data={works} containerClass="prod-grid-master-container" />
          </div>
        </div>
      </section>
      <InfoBox />
      <Footer />
    </>
  )
}
