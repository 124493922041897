import React from "react";

const InfoBox = ({ title, titleId, items }) => {
  return (
    <section id="info-box">
      <div class="container py-5 text-center">
        <div class="row mt-5 mb-5">
          <div class="row mb-4">
            <div class="col">
              <h1 class="display-5 fw-bold">支持我們</h1>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-3">
              <h2 class="fw-bold">重用</h2>
              <p>香港每日丟棄數百噸的樹木，假如不回收升級再造，它們只會被送往堆填區。</p>
            </div>
            <div class="col-md-3">
              <h2 class="fw-bold">手工</h2>
              <p>人手製作每件作品都是獨一無二。</p>
            </div>
            <div class="col-md-3">
              <h2 class="fw-bold">再生</h2>
              <p>使用的木材均屬環保再生、循環再造。</p>
            </div>
            <div class="col-md-3">
              <h2 class="fw-bold">自然</h2>
              <p>賦予每件家具獨一無二的天然故事。</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoBox;
