import React from "react";

import ProductItem from "./ProductItem";

const ProductGrid = ({ data, containerClass = "prod-grid-container", gridItemClass = "prod-grid" }) => {
  return (
    <div class={`${containerClass} g-4 mt-0`}>
      {data.map((row) => {
        return <ProductItem key={row.id} data={row} gridItemClass={gridItemClass} />;
      })}
    </div>
  );
};

export default ProductGrid;
